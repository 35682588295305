// src/components/SEO.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  page: keyof typeof SEOData;
}

export const SEOData = {
  home: {
    title: 'Cesta Světla – Theta Healing',
    description:
      'Prožij naplno pocit, že si zasloužíš lásku a soucit. Objev cestu ke změně s metodou Theta Healing, která tě povede k úlevě, radosti, a osobnímu růstu. Cesta Světla je tu, aby ti pomohla nalézt sebe a žít plný, spokojený život.',
    keywords:
      'Theta Healing, osobní růst, sebeláska, respekt, empatie, soucit, laskavost, radost, změna, spirituální rozvoj, sebepoznání',
    url: 'https://www.cestasvetla.eu/',
    image: null,
  },
  about: {
    title: 'O mně – Můj příběh | Theta Healing a Osobní Růst',
    description:
      'Přečti si příběh Veroniky Hývlové, která překonala bolesti a objevila vnitřní svobodu. Díky metodě Theta Healing teď pomáhá lidem na jejich cestě k uzdravení, radosti a klidu v duši.',
    keywords:
      'O mně, Veronika Hývlová, Theta Healing, osobní příběh, sebeláska, psychické zdraví, uzdravení, spirituální rozvoj, změna života',
    url: 'https://www.cestasvetla.eu/about',
    image: null,
  },
  articles: {
    title: 'Články – Inspirace a příběhy | Otázky a odpovědi',
    description:
      'Objev inspirativní články, příběhy klientů a praktické tipy z oblasti Theta Healing a osobního růstu. Prozkoumej cesty k harmonii v těle, mysli a duši.',
    keywords:
      'články, inspirace, Theta Healing, osobní růst, příběhy klientů, praktické tipy, spirituální rozvoj, Veronika Hývlová, seberozvoj',
    url: 'https://www.cestasvetla.eu/articles',
    image: null,
  },
  feedback: {
    title: 'Zpětná vazba – Co říkají klienti',
    description:
      'Přečti si zpětnou vazbu od klientů a zjisti, jak jim Theta Healing pomohl na jejich cestě k osobnímu růstu. Sdílej i ty svůj názor a zkušenosti.',
    keywords:
      'zpětná vazba, klientské recenze, Theta Healing zkušenosti, osobní růst, recenze, Veronika Hývlová, spirituální cesta',
    url: 'https://www.cestasvetla.eu/feedback',
    image: null,
  },
  reservation: {
    title: 'Rezervace termínu – Theta Healing sezení',
    description:
      'Rezervuj si svůj termín na Theta Healing sezení. Možnost uplatnění slevových kódů. Těším se na společnou cestu ke změně a růstu!',
    keywords:
      'rezervace, Theta Healing, slevové kódy, osobní růst, spirituální terapie, Veronika Hývlová, objednání sezení',
    url: 'https://www.cestasvetla.eu/reservation',
    image: null,
  },
  contact: {
    title: 'Kontakt – Pošli mi zprávu',
    description:
      'Máte otázky ohledně Theta Healing? Kontaktujte mě prostřednictvím formuláře nebo sociálních sítí. Ráda vám pomohu na cestě ke změně a harmonii.',
    keywords:
      'kontakt, Theta Healing, Veronika Hývlová, otázky, formulář, sociální sítě, pomoc, spirituální růst',
    url: 'https://www.cestasvetla.eu/contact',
    image: null,
  },
  certification: {
    title: 'Certifikace – Theta Healing certifikace a kvalifikace',
    description:
      'Zjistěte více o mých certifikacích a kvalifikacích v oblasti Theta Healing. Důvěřujte odbornosti a zkušenostem na cestě k vaší změně a harmonii.',
    keywords:
      'certifikace, Theta Healing, Veronika Hývlová, kvalifikace, odbornost, spirituální růst, energetické léčení',
    url: 'https://www.cestasvetla.eu/certification',
    image: null,
  },
  gdpr: {
    title: 'Ochrana osobních údajů a politika Cookies | Cesta Světla',
    description:
      'Zjistěte, jak chráníme vaše osobní údaje v souladu s GDPR a jak fungují cookies na našem webu. Přečtěte si naše zásady ochrany osobních údajů a pravidla pro používání cookies.',
    keywords:
      'GDPR, ochrana osobních údajů, politika cookies, soukromí, zpracování dat, osobní údaje, bezpečnost, Cesta Světla',
    url: 'https://www.cestasvetla.eu/gdpr',
    image: null,
  },
  contract: {
    title: 'Obchodní podmínky | Cesta Světla',
    description:
      'Přečtěte si obchodní podmínky pro produkty a služby Cesty Světla. Informace o objednávkách, platbách, dodání, reklamacích a ochraně autorských práv.',
    keywords:
      'obchodní podmínky, podmínky nákupu, objednávky, platby, reklamační řád, ochrana autorských práv, Cesta Světla',
    url: 'https://www.cestasvetla.eu/contract',
    image: null,
  },
  qa: {
    title: 'Theta Healing - Často Kladené Otázky (FAQ)',
    description:
      'Zjisti vše o Theta Healingu a jak probíhá sezení. Odpovědi na často kladené otázky ohledně online sezení, přípravy, trvání a dalších informací.',
    keywords:
      'Theta Healing, FAQ, online sezení, příprava na sezení, jak probíhá sezení, otázky a odpovědi, energetické léčení, meditace, zdraví, duševní pohoda, léčebné techniky',
    url: 'https://www.cestasvetla.eu/articles/qa',
    image: null,
  },
  theta: {
    title:
      'Theta Healing: Jak ti tato metoda může pomoci dosáhnout cílů a zbavit se bloků',
    description:
      'Zjisti, co je Theta Healing a jak tato metoda energetického léčení může pomoci uvolnit bloky, zvýšit tvůj úspěch a léčit tělo i duši. Sezení probíhají online, zaměřují se na pozitivní změny a uvolnění v tvém životě.',
    keywords:
      'Theta Healing, energetické léčení, meditace, bloky, cíl, osobní rozvoj, Stvořitel, léčení těla, duševní zdraví, pozitivní změny, online sezení, Vianna Stibal, odstranění negativních přesvědčení',
    url: 'https://www.cestasvetla.eu/articles/theta',
    image: null,
  },
  stvoritel: {
    title:
      'Energie Stvoření: Co je a jak ji můžeme využít pro transformaci života',
    description:
      'Energie stvoření je univerzální síla, která prostupuje vším, co existuje. Objev, jak tuto energii vnímat, jak ovlivňuje tvůj život a jak ji využít k pozitivním změnám, meditacím a uzdravení.',
    keywords:
      'energie stvoření, univerzální síla, vibrace, meditace, transformace, energetické cvičení, zákon přitažlivosti, pozitivní změny, Theta healing, intuice, duchovní praxe, Prána, Tao, Čchi',
    url: 'https://www.cestasvetla.eu/articles/stvoritel',
    image: null,
  },
  prubeh: {
    title: 'Online Sezení Theta Healing: Jak probíhá',
    description:
      'Prožij online sezení Theta Healing, kde tě provedu meditací k propojení se Stvořitelem a léčení emocionálních bloků. Společně objevíme kořenová přesvědčení a nahradíme je pozitivními změnami. Laskavý přístup a individuální péče.',
    keywords:
      'online sezení, Theta Healing, meditace, propojení se Stvořitelem, léčení emocí, energetické léčení, pozitivní změny, kořenová přesvědčení, emocionální bloky, bezpodmínečná láska, energetická terapie, uzdravení, vnitřní klid',
    url: 'https://www.cestasvetla.eu/articles/prubeh',
    image: null,
  },
  thanks: {
    title:
      'Děkujeme za důvěru – Objevte více o Theta Healing a dalších tématech',
    description:
      'Děkujeme za vaši důvěru. Pokračujte v prozkoumávání našich článků a zdrojů o Theta Healing a dalších tématech, které vám pomohou na vaší cestě osobního růstu a uzdravení.',
    keywords:
      'děkujeme za důvěru, Theta Healing, osobní růst, uzdravení, články o spiritualitě, meditace, energetické léčení, osobní rozvoj, články o zdraví, pokračujte v učení',
    url: 'https://www.cestasvetla.eu/thankyou',
    image: null,
  },
  barter: {
    title: 'Barterová spolupráce - Theta Healing sezení | Cesta Světla',
    description:
      'Domluv se na barterové spolupráci a zažij Theta Healing sezení. Nabízím varianty 60 a 90 minut, které ti pomohou objevit nový pohled na život. Přijď si vyzkoušet sílu Theta Healingu ještě dnes!',
    keywords:
      'barterová spolupráce, Theta Healing, sezení 60 minut, sezení 90 minut, léčení, meditace, osobní rozvoj, barter výměna, spolupráce',
    url: 'https://www.cestasvetla.eu/barter',
    image: null,
  },
  //articlesID
};

const SEO: React.FC<SEOProps> = ({ page }) => {
  const seo = SEOData[page];
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{seo.title}</title>
      <meta name='description' content={seo.description} />
      <meta name='author' content='Cesta Světla' />
      {seo.keywords && <meta name='keywords' content={seo.keywords} />}

      {/* Canonical Link */}
      <link rel='canonical' href={seo.url} />

      {/* Open Graph / Facebook */}
      <meta property='og:title' content={seo.title} />
      <meta property='og:description' content={seo.description} />
      {seo.image && <meta property='og:image' content={seo.image} />}
      <meta property='og:url' content={seo.url} />
      <meta property='og:type' content='website' />

      {/* Twitter */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      {seo.image && <meta name='twitter:image' content={seo.image} />}

      {/* Structured Data */}
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Organization',
          url: seo.url,
          name: seo.title,
          sameAs: [
            'https://www.facebook.com/profile.php?id=61566270399078&locale=cs_CZ',
            'https://www.instagram.com/cestasvetla/',
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
