import React from 'react';
import FeedbackComponent from '../components/FeedbackComponent';
import StyledButton from '../components/StyledButton';
import { handleOpenFeedbackForm } from '../helpers/navigations';
import { useAppContext } from '../context/AppContext';

const FeedbackPage: React.FC = () => {
  const { state } = useAppContext();

  return (
    <div className='top-0 pt-24 w-full min-h-screen bg-transparent flex flex-col items-center justify-center'>
      <div className='top-24 pt-8 lg:p-8 pl-[3%] pr-[3%] lg:pl-[7%] lg:pr-[7%] bg-custom-test w-full xl:w-7/12 text-center'>
        <p className=' text-pretty text-lg text-black xl:pt-4 text-center'>
          {'Děkuji za důvěru ❤️'}
        </p>
      </div>

      {/* Scrollable Page Content */}
      <div className='relative z-0 px-6 bg-custom-test w-full xl:w-7/12'>
        <div className='pb-10 bg-transparent'>
          {/* Feedback Section */}

          {state.feedbacks.map(
            (
              feedback: {
                image: string;
                name: string;
                text: string;
                job: string;
              },
              index: React.Key | null | undefined
            ) => (
              <FeedbackComponent
                key={index}
                image={'/content-data/feedback/img/' + feedback.image}
                name={feedback.name}
                job={feedback.job}
                text={'/content-data/feedback/txt/' + feedback.text}
              />
            )
          )}
        </div>
      </div>
      <div className='flex lg:p-8 lg:pb-12 lg:pt-0 mb-24 pl-[3%] pr-[3%] lg:pl-[7%] lg:pr-[7%] bg-custom-test w-full xl:w-7/12 text-center justify-center'>
        <p>
          <StyledButton
            handleClick={handleOpenFeedbackForm}
            text={'Chceš mi dát svojí zpětnou vazbu?'}
          />
        </p>
      </div>
    </div>
  );
};

export default FeedbackPage;
