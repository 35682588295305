import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArticleTitle from './ArticleTitle';
import mammoth from 'mammoth';
import AuthorSign from './AuthorSign';
import QAContent from './articles/QAContent';
import StyledButton from './StyledButton';
import ReactDOM from 'react-dom';
import NextArticles from './NextArticles';
import Subscribe from './Subscribe';
import img2 from '../util/img/sm_DSC_6032.webp';
import SEO from './SEO';
import {
  handleNavigateToAbout,
  handleNavigateToPrubeh,
  handleNavigateToQA,
  handleNavigateToReservation,
  handleNavigateToStvoritel,
  handleNavigateToThetaArticle,
} from '../helpers/navigations';
import { useAppContext } from '../context/AppContext';
import WhyThetaContent from './articles/WhyThetaContent';

const Article: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [article, setArticle] = useState<any>({});
  const [textData, setTextData] = useState<string>('');
  const { setActiveMeeting } = useAppContext();
  const contentRef = useRef(null);
  const navigate = useNavigate();

  const getSEO = (idStr: string) => {
    if (idStr === 'qa') {
      return idStr;
    }
    if (idStr === 'theta') {
      return idStr;
    }
    if (idStr === 'stvoritel') {
      return idStr;
    }
    if (idStr === 'prubeh') {
      return idStr;
    } else {
      return 'articles';
    }
  };

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash, textData]);

  useEffect(() => {
    fetch('/content-data/articles/articles.json')
      .then((response) => response.json())
      .then((data) => {
        const article = data.articles.find(
          (article: { id: string }) => article.id === id
        );
        setArticle(article);
      })
      .catch((error) => console.error('Error loading feedback data:', error));
  }, [id]);

  useEffect(() => {
    fetch('/content-data/articles/txt/' + article.content)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        // Convert the DOCX binary data to HTML
        return mammoth.convertToHtml(
          { arrayBuffer },
          {
            styleMap: [
              "p[style-name='Heading 1'] => h1:fresh",
              "p[style-name='Heading 2'] => h2:fresh",
              "p[style-name='Normal'] => p:fresh", // Handle normal paragraphs
              "p[style-name='No Spacing'] => p:fresh", // Handle paragraphs without spacing
              'b => strong', // Convert bold text to strong
              'i => em', // Convert italic text to em
              'u => u', // Convert underlined text to <u>
              "p[style-name='Hyperlink'] => a", // Handle hyperlinks
              'r => br', // Convert hard line breaks
              'ul => ul.mammoth-list', // Add class to <ul> for custom styling
              'li => li.mammoth-item', // Add class to <li> for square styling
            ],
          }
        );
      })
      .then((result) => {
        let modifiedHtml = result.value; // Extracted HTML
        console.log(modifiedHtml);
        if (id === 'why-theta') {
          modifiedHtml = modifiedHtml.replace(
            '(links)',
            "<div id='button-placeholder3'></div>" // Placeholder where the button will go
          );
        }
        if (id === 'prubeh') {
          // Inject a placeholder for the button
          modifiedHtml = modifiedHtml.replace(
            '(odkaz na rezervuj si sezení se mnou)',
            "<div id='button-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(foto1)',
            "<div id='img1-placeholder'></div>" // Placeholder where the button will go
          );
        }
        if (id === 'theta') {
          // Inject a placeholder for the button
          modifiedHtml = modifiedHtml.replace(
            '(Více o mém příběhu zde)',
            "<div id='button-placeholder2'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(odkaz na rezervuj si sezení se mnou)',
            "<div id='button-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(svoreni)',
            "<div id='button-placeholder4'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(foto1)',
            "<div id='img1-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replaceAll(
            '<ul>',
            '<ul class="mammoth-list">' // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replaceAll(
            '<li>',
            '<li class="mammoth-item">' // Placeholder where the button will go
          );
        }
        setTextData(modifiedHtml); // Set HTML into state
      })
      .catch((error) => console.error('Error loading document:', error));
  }, [article, id]);

  useEffect(() => {
    // After the HTML is rendered, find the placeholder and inject the button
    if (
      (id === 'theta' || id === 'prubeh' || id === 'why-theta') &&
      contentRef.current
    ) {
      const placeholder = document.getElementById('button-placeholder');
      const placeholder1 = document.getElementById('img1-placeholder');
      const placeholder2 = document.getElementById('button-placeholder2');
      const placeholder3 = document.getElementById('button-placeholder3');
      const placeholder4 = document.getElementById('button-placeholder4');
      if (placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <StyledButton
            handleClick={() => handleNavigateToReservation(navigate)}
            text='Rezervuj si sezení se mnou'
          />,
          // <StyledButton
          //   handleClick={() => handleNavigateToReservation(navigate)}
          //   text='Rezervuj si sezení se mnou'
          // />
          placeholder
        );
      }
      if (placeholder2) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <StyledButton
            handleClick={() => {
              handleNavigateToAbout(navigate);
            }}
            text='Víc o mém příběhu'
          />,
          placeholder2
        );
      }
      if (placeholder4) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <>
            <div className='h-px w-full bg-secondary-button-color mb-5' />
            <p>
              Říkáš si, co je to ta energie stvoření, se kterou se v theta
              healingu pracuje? Potom se můžeš podívat na{' '}
              <span
                className='font-[600] underline cursor-pointer text-amber-900'
                onClick={() => {
                  handleNavigateToStvoritel(navigate);
                }}
              >
                tento
              </span>{' '}
              článek.{' '}
            </p>
          </>,
          placeholder4
        );
      }
      if (placeholder1) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={img2}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          placeholder1
        );
      }
      if (placeholder3) {
        ReactDOM.render(
          <div>
            <StyledButton
              handleClick={() => {
                setActiveMeeting('konzultace');
                handleNavigateToReservation(navigate);
              }}
              text='Chci Vyzkoušet 20 min zdarma'
            />
            <p
              className='pt-4 underline cursor-pointer font-[600] transition-all duration-300 ease-in-out hover:scale-[1.02]'
              onClick={() => handleNavigateToPrubeh(navigate)}
            >
              Průběh sezení se mnou
            </p>
            <p
              className='underline cursor-pointer font-[600] transition-all duration-300 ease-in-out hover:scale-[1.02]'
              onClick={() => handleNavigateToThetaArticle(navigate)}
            >
              Jak funguje theta healing
            </p>
            <p
              className='underline cursor-pointer font-[600] transition-all duration-300 ease-in-out hover:scale-[1.02]'
              onClick={() => handleNavigateToQA(navigate)}
            >
              Otázky a odpovědi
            </p>
          </div>,
          placeholder3
        );
      }
    }
  }, [textData]);

  return (
    <>
      <SEO page={getSEO(id ?? 'articles')} />
      <div className='top-0 pt-24 w-full min-h-screen bg-transparent flex flex-col items-center justify-center'>
        <section className='flex flex-col lg:p-16 lg:pl-[25%] lg:pr-[25%] bg-transparent items-center justify-center w-full'>
          <div className='text-center pt-6 bg-white w-full'>
            <ArticleTitle text={article.title} />
          </div>
          {id === 'why-theta' && <WhyThetaContent />}
          {id === 'qa' && <QAContent />}
          {id !== 'qa' && id !== 'why-theta' && (
            <div className='bg-white p-4 lg:pl-20 lg:pr-20 lg:pb-16'>
              {' '}
              <div
                ref={contentRef}
                onLoadedData={() => console.log('loaded')}
                dangerouslySetInnerHTML={{ __html: textData }} // Insert HTML into the component
              />
            </div>
          )}
        </section>
        <NextArticles />
        <AuthorSign />
        <Subscribe />
      </div>
    </>
  );
};

export default Article;
