import React, { useEffect, useState } from 'react';
import mammoth from 'mammoth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

const fallbackSrc = '/content-data/feedback/img/default.jpg';

interface FeedbackComponentProps {
  image: string;
  name: string;
  text: string;
  job: string;
  highlight?: boolean;
}

const FeedbackComponentHome: React.FC<FeedbackComponentProps> = ({
  image,
  name,
  text,
  job,
  highlight = false,
}) => {
  const [textData, setTextData] = useState<string>('');
  const [imgSrc, setImgSrc] = useState<string>(image);

  const handleError = () => {
    setImgSrc(fallbackSrc); // Set fallback image if the original image fails to load
  };

  useEffect(() => {
    fetch(text)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        // Convert the binary data to a readable format using Mammoth
        return mammoth.convertToHtml({ arrayBuffer });
      })
      .then((result) => {
        let modifiedHtml = result.value;
        modifiedHtml = modifiedHtml.replace(
          /<img /g,
          '<img loading="lazy" class="inline w-4 h-4 mr-1 ml-1" '
        );
        setTextData(modifiedHtml);
      }) // Extract text
      .catch((error) => console.error('Error loading document:', error));
  });

  return (
    <>
      <div className='flex flex-col min-w-[25%] justify-start items-center pt-8 '>
        <div className='relative items-center text-amber-900/90 -ml-4 pb-6'>
          <FontAwesomeIcon icon={faQuoteLeft} size='xl' />
        </div>
        <div
          className='text-black font-[500] p-4 text-pretty text-lg'
          dangerouslySetInnerHTML={{ __html: textData }}
        />
        <div className='relative items-center text-amber-900/90 -ml-4 pt-6 pb-6'>
          <FontAwesomeIcon icon={faQuoteRight} size='xl' />
        </div>
        {/* <div className="my-auto" /> */}
        <div className='flex flex-col items-center pb-8'>
          <img
            loading='lazy'
            src={imgSrc}
            alt={name}
            onError={handleError}
            className='w-24 h-24 rounded-full aspect-square object-cover '
          />

          <div className=''>
            <p className="text-lg font-bold text-amber-900 font-['MyCustomFont2'] text-center">
              {name}
            </p>
            <p className="text-sm font-bold text-amber-900/80 font-['MyCustomFont2'] text-center">
              {job}
            </p>
          </div>
        </div>
        <div className='lg:hidden h-px w-full bg-secondary-button-color mb-5' />
      </div>
    </>
  );
};

export default FeedbackComponentHome;

//
