import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import StyledButton from './StyledButton';
import { Discount, useAppContext } from '../context/AppContext';
import { validateDiscountBE } from '../helpers/phpHelper';

interface ReservationItemProps {
  text: string;
  subtext?: string;
  leftIcon: IconProp;
  onValidDiscount: (refLink: Discount | null) => void;
}
//TODO: validate php, load php, invalidate code after usage
const DiscountItem: React.FunctionComponent<ReservationItemProps> = ({
  text,
  subtext,
  leftIcon,
  onValidDiscount,
}) => {
  const { state, setActiveDiscount } = useAppContext();
  const form = useRef<HTMLFormElement | null>(null);

  const [discountValid, setDiscountValid] = useState<boolean | null>(null);
  const [retDiscount, setRetDiscount] = useState<Discount | null>(null);

  const getPlaceholderText = () => {
    if (discountValid === null) return 'Zadej slevový kód';
    if (discountValid) return '';
    if (!discountValid) return 'Neznámý kód';
  };

  const validateDiscount = (code: string) => {
    validateDiscountBE(code.toUpperCase())
      .then((response) => {
        const matchFound = response || false;
        setDiscountValid(matchFound ? true : false);
        if (!matchFound) {
          form.current?.reset();
          setActiveDiscount(null);
        } else {
          setRetDiscount(matchFound);
          setActiveDiscount(matchFound);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        form.current?.reset();
        setActiveDiscount(null);
      });
    //validate in php
  };

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (form.current) {
      validateDiscount(form.current?.discount_code.value);
    }
  };

  useEffect(() => {
    if (discountValid) onValidDiscount(retDiscount);
    else onValidDiscount(null);
  }, [discountValid]);

  return (
    <section
      className={`flex flex-col lg:flex-row w-full justify-between p-2 pl-4 pr-4  bg-custom-test`}
    >
      <div className='flex flex-col lg:flex-row text-center items-center'>
        {/* <p className='text-black'>
          <FontAwesomeIcon
            className={`relative bottom-0 h-[1.5rem] w-[1.5rem] lg:pr-4`}
            icon={leftIcon}
            size='xl'
          />
        </p> */}
        <h1 className='font-[500] '>
          {state.activeDiscount
            ? 'Aktivní sleva: ' + state.activeDiscount.id
            : text}
        </h1>
      </div>
      {!state.activeDiscount && (
        <form
          ref={form}
          onSubmit={formSubmit}
          className='flex flex-col lg:flex-row pt-4 lg:pt-0 p-0 m-0 justify-center items-center space-y-3 lg:space-y-0'
        >
          <div className='flex flex-row min-h-10'>
            <input
              type='text'
              name='discount_code'
              placeholder={getPlaceholderText()}
              className='block pl-10 pr-10 w-full min-h-5 rounded-sm lg:rounded-xl text-base focus:border-transparent'
              required
            />
          </div>
          <div className='flex lg:ml-6 max-h-10'>
            <StyledButton text={'Uplatnit'} handleClick={() => {}} />
          </div>
        </form>
      )}
      {state.activeDiscount && (
        <StyledButton
          text={'Zrušit'}
          handleClick={() => {
            setActiveDiscount(null);
            form.current?.reset();
            setDiscountValid(null);
            setRetDiscount(null);
          }}
        />
      )}
    </section>
  );
};

export default DiscountItem;
