import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../context/AppContext';
import StyledButton from './StyledButton';

interface VoucherBuyItemProps {
  text: string;
  subtext?: string;
  leftIcon?: IconProp;
  hide?: boolean;
  enhance?: boolean;
  onClick60: () => void;
  onClick90: () => void;
}

const VoucherBuyItem: React.FunctionComponent<VoucherBuyItemProps> = ({
  text,
  subtext,
  leftIcon,
  hide = false,
  enhance = false,
  onClick60,
  onClick90,
}) => {
  const { state, setActiveMeeting } = useAppContext();

  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    if (!state.activeMeeting) setIsVisible(true);
    else {
      setIsVisible(false);
    }
  }, [state.activeMeeting]);

  return (
    <section
      className={`${
        isVisible ? 'flex' : 'hidden'
      } flex-col lg:flex-row w-full justify-between p-4 ${
        enhance ? 'bg-white' : 'bg-custom-test'
      } cursor-pointer`}
    >
      <div className='flex flex-col lg:flex-row items-center'>
        <h1 className='font-[500]'>{text}</h1>
      </div>
      <div className='flex flex-col lg:flex-row items-center justify-center text-center pt-3 lg:pt-0 '>
        <p className='flex pr-4 max-h-10'>
          <StyledButton
            handleClick={() => {
              onClick60();
            }}
            text='60 minut'
          />
        </p>
        <p className='flex max-h-10'>
          <StyledButton
            handleClick={() => {
              onClick60();
            }}
            text='90 minut'
          />
        </p>
      </div>
    </section>
  );
};

export default VoucherBuyItem;
