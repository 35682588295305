import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from 'react';
import { fetchDiscounts, fetchFeedbacks } from '../helpers/stateFetchers';
import { Discount, Feedback, User, Voucher } from './interfaces';

interface AppState {
  user: User | null;
  activeDiscount: Discount | null;
  activeMeeting: string | null;
  buyVoucherProcessor: Voucher | null;
  feedbacks: Feedback[];
}

interface AppContextProps {
  state: AppState;
  setState: React.Dispatch<React.SetStateAction<AppState>>;
  setActiveDiscount: (activeDiscount: Discount | null) => void;
  setActiveMeeting: (activeMeeting: string | null) => void;
  setVoucherProcessor: (buyVoucherProcessor: Voucher | null) => void;
  setFeedbacks: (feedbacks: Feedback[]) => void;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

const initState: AppState = {
  user: null,
  activeDiscount: null,
  activeMeeting: null,
  buyVoucherProcessor: null,
  feedbacks: [],
};

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [state, setState] = useState<AppState>(initState);

  const setUser = (user: any) => {
    setState((prevState) => ({ ...prevState, user }));
  };

  const setActiveDiscount = (activeDiscount: Discount | null) => {
    setState((prevState) => ({ ...prevState, activeDiscount }));
  };

  const setActiveMeeting = (activeMeeting: string | null) => {
    setState((prevState) => ({ ...prevState, activeMeeting }));
  };

  const setVoucherProcessor = (buyVoucherProcessor: Voucher | null) => {
    setState((prevState) => ({ ...prevState, buyVoucherProcessor }));
  };
  const setFeedbacks = (feedbacks: Feedback[]) => {
    setState((prevState) => ({ ...prevState, feedbacks }));
  };

  useEffect(() => {
    fetchFeedbacks().then((resp) => setFeedbacks(resp ?? []));
  });

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
        setActiveDiscount,
        setActiveMeeting,
        setVoucherProcessor,
        setFeedbacks,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export type { Discount };
