import React, { useState } from 'react';
import ArticleTitle from '../components/ArticleTitle';
import ReservationItem from '../components/ReservationItem';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const BarterPage: React.FC = () => {
  const [currentSelection, setCurrentSelection] = useState<string | null>(null);
  const handleItemClick = (refLink: string | null) => {
    setCurrentSelection(refLink);
  };

  return (
    <div className='top-0 pt-24 mb-30 w-full lg:pl-[10%] lg:pr-[10%] bg-transparent flex flex-col items-center justify-center'>
      <div className='flex flex-col w-full pt-8 bg-white/90 text-center mb-[10rem] items-center'>
        <ArticleTitle text='Rezervace barterové spolupráce' />
        <section className='flex flex-col w-full p-3 space-y-1'>
          <ReservationItem
            text='Theta healing sezení - 60 minut'
            price={-1}
            discountPrice='2000 Kč'
            leftIcon={faClock}
            onClick={handleItemClick}
            refLink='barter60'
          />
          {/* <ReservationItem
            text='Theta healing sezení - 90 minut'
            price={-1}
            discountPrice='2500 Kč'
            leftIcon={faClock}
            onClick={handleItemClick}
            refLink='barter90'
          /> */}
        </section>
        {currentSelection && (
          <div
            style={{
              width: '100%',
              height: '100vh',
              offset: '10rem',
              overflow: 'contain',
            }}
            className='pl-3 pr-3 overflow-hidden'
          >
            <iframe
              title='barterFrame'
              src={`https://app.simplymeet.me/cestasvetla/${currentSelection}?is_widget=1&view=compact`}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                offset: '10rem',
                overflow: 'contain',
              }}
              frameBorder='0'
              scrolling='no'
            />
          </div>
        )}

        <h2 className='text-2xl font-bold pb-8 pt-8 text-amber-900'>
          Těším se na tebe!
        </h2>
      </div>
    </div>
  );
};

export default BarterPage;
