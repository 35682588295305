import React from 'react';
import './index.css';
import App from './App';
import { AppProvider } from './context/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';

library.add(faInstagram, faFacebook);
ReactGA.initialize('G-J0NY7ECRBM');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <AppProvider>
        <BrowserRouter basename='/'>
          <App />
        </BrowserRouter>
      </AppProvider>
    </HelmetProvider>
  </React.StrictMode>
);
