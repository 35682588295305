import { Discount } from '../context/AppContext';
import { Feedback } from '../context/interfaces';

export const fetchDiscounts = async () =>
  //setIsLoading: (loading: boolean) => void
  {
    try {
      const response = await fetch('/content-data/discounts.json');
      const jsonData = await response.json();
      return jsonData as Discount[];
    } catch (error) {
      console.error('Failed to load JSON data:', error);
    } finally {
      //setIsLoading(false);
    }
  };

export const fetchFeedbacks = async () =>
  //setIsLoading: (loading: boolean) => void
  {
    try {
      const response = await fetch('/content-data/feedback/feedback.json');
      const jsonData = await response.json();
      return jsonData.feedbacks as Feedback[];
    } catch (error) {
      console.error('Failed to load JSON data:', error);
    } finally {
      //setIsLoading(false);
    }
  };
