import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../context/AppContext';
import StyledButton from './StyledButton';

interface ReservationItemProps {
  text: string;
  subtext?: string;
  price: number;
  discountPrice?: string;
  leftIcon: IconProp;
  hide?: boolean;
  enhance?: boolean;
  isVoucher?: boolean;
  displayvoucher?: boolean;
  onClick: (refLink: string | null) => void;
  onVoucher?: () => void;

  refLink: string | null;
}

const zoomAnimation =
  'transition-all duration-500 ease-in-out transform hover:scale-[1.01] hover:shadow-inner';

const ReservationItem: React.FunctionComponent<ReservationItemProps> = ({
  text,
  subtext,
  price,
  discountPrice,
  leftIcon,
  hide = false,
  isVoucher = false,
  displayvoucher = false,
  enhance = false,
  onClick,
  onVoucher,
  refLink,
}) => {
  const { state, setActiveMeeting } = useAppContext();

  const [displayPrice, setDisplayPrice] = useState<string | null>(
    price.toString() + ' Kč'
  );
  const [displayDiscount, setDisplayDiscount] = useState<string | undefined>(
    discountPrice
  );
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    if (!state.activeMeeting) setIsVisible(true);
    else if (
      refLink &&
      state.activeMeeting &&
      state.activeMeeting.includes(refLink)
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [state.activeMeeting]);

  useEffect(() => {
    if (price === -1) {
      setDisplayPrice('Barter');
      return;
    }
    if (price === -2) {
      setDisplayPrice('Zdarma');
      return;
    }
    if (!state.activeDiscount) {
      setDisplayDiscount(discountPrice);
      setDisplayPrice(price.toString() + 'Kč');
    }
    if (state.activeDiscount?.discountPerc && price !== -1 && price !== -2) {
      if (!discountPrice) {
        setDisplayDiscount(price.toString() + ' Kč');
      }
      setDisplayPrice(
        (price * (1 - state.activeDiscount.discountPerc)).toString() + ' Kč'
      );
    }
    if (state.activeDiscount?.discountVal && price !== -1 && price !== -2) {
      if (!discountPrice) {
        setDisplayDiscount(price.toString() + ' Kč');
      }
      setDisplayPrice(
        (price - state.activeDiscount.discountVal).toString() + ' Kč'
      );
    }

    if (
      refLink &&
      state.activeMeeting &&
      state.activeMeeting.includes(refLink)
    ) {
      const retLink = buildLink(refLink);
      setActiveMeeting(retLink);
      onClick(retLink);
    }
  }, [state.activeDiscount]);

  const buildLink = (link: string | null) => {
    if (isVoucher) {
      if (refLink === 'vou60') {
        return '0012024DAREK60';
      }
      if (refLink === 'vou90') {
      } else {
        return '0012024DAREK90';
      }
    }
    if (state.activeDiscount)
      return link + state.activeDiscount.id.toLowerCase();
    else return link;
  };

  const parts = text.split(/(\*\*.*?\*\*)/);

  return (
    <section
      className={`${
        isVisible ? 'flex' : 'hidden'
      } flex-col lg:flex-row w-full justify-between p-4 ${
        enhance ? 'bg-white' : 'bg-custom-test'
      } ${zoomAnimation} cursor-pointer`}
      onClick={() => {
        if (isVisible && state.activeMeeting !== null) {
          setActiveMeeting(null);
        } else {
          const retLink = buildLink(refLink);
          setActiveMeeting(retLink);
          onClick(retLink);
        }
      }}
    >
      <div className='flex flex-col lg:flex-row items-center'>
        {state.activeMeeting && (
          <p className='flex pr-3'>
            <StyledButton
              handleClick={() => {
                setActiveMeeting(null);
              }}
              text='Zpět na výběr'
            />
          </p>
        )}
        {/* <p className='text-black'>
          <FontAwesomeIcon
            className={`relative bottom-0 h-[1.5rem] w-[1.5rem] lg:pr-4`}
            icon={leftIcon}
            size='xl'
          />
        </p> */}
        <h1 className='font-[500]'>
          {parts.map((part, index) =>
            part.startsWith('**') && part.endsWith('**') ? (
              <strong key={index}>{part.slice(2, -2)}</strong> // Remove **
            ) : (
              part
            )
          )}
        </h1>
      </div>
      <div className='flex flex-col lg:flex-row items-center justify-center text-center pt-3 lg:pt-0 lg:pr-3'>
        {displayvoucher && (
          <p className='flex pr-6 max-h-6'>
            <StyledButton
              handleClick={() => {
                onVoucher && onVoucher();
              }}
              text='Koupit Voucher'
            />
          </p>
        )}
        {displayDiscount && (
          <p className='line-through lg:pr-3'>{displayDiscount}</p>
        )}
        <p className='font-[600]'>{displayPrice}</p>
      </div>
    </section>
  );
};

export default ReservationItem;
