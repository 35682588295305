import { NavigateFunction } from 'react-router-dom';

//navigation
export const handleNavigateToQA = (navigate: NavigateFunction) => {
  navigate('/articles/qa');
};

export const handleNavigateToPrubeh = (navigate: NavigateFunction) => {
  navigate('/articles/prubeh');
};

export const handleNavigateToAbout = (navigate: NavigateFunction) => {
  navigate('/about');
};

export const handleNavigateToReservation = (navigate: NavigateFunction) => {
  navigate('/reservation');
};

export const handleNavigateToThetaArticle = (navigate: NavigateFunction) => {
  navigate('/articles/theta');
};

export const handleNavigateToWhyThetaArticle = (navigate: NavigateFunction) => {
  navigate('/articles/why-theta');
};

export const handleNavigateToFeedback = (navigate: NavigateFunction) => {
  navigate('/feedback');
};

export const handleNavigateContact = (navigate: NavigateFunction) => {
  navigate('/contact');
};

export const handleNavigateToStvoritel = (navigate: NavigateFunction) => {
  navigate('/articles/stvoritel');
};

//rest
export const handleOpenFeedbackForm = () => {
  const url = 'https://forms.gle/Z9CCAmkb96KqyXES8';
  window.open(url, '_blank', 'noopener,noreferrer');
};
