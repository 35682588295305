import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Redirect: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/'); // Redirect to home or any target path
    }, 3000); // Delay for 3 seconds

    return () => clearTimeout(timeout); // Cleanup timeout
  }, [navigate]);

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-700'>
      <h1 className='text-4xl font-bold mb-4'>Redirecting...</h1>
      <p className='text-lg mb-8'>You will be redirected shortly.</p>
    </div>
  );
};

export default Redirect;
