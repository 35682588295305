import React, { useEffect, useState } from 'react';
import ArticleLink from '../components/ArticleLink';
import Subscribe from '../components/Subscribe';
import AuthorSign from '../components/AuthorSign';
import ArticleTitle from '../components/ArticleTitle';

const ThankYouPage: React.FC = () => {
  const [articles, setArticles] = useState<any>({});
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000); // 2000 milliseconds = 2 seconds

    fetch('/content-data/articles/articles.json')
      .then((response) => response.json())
      .then((data) => {
        setArticles(data);
      })
      .catch((error) => console.error('Error loading feedback data:', error));
  }, []);

  return (
    <div className='top-0 pt-24 w-full min-h-screen bg-transparent flex flex-col justify-center'>
      <section
        className={
          'p-8 pb-20 lg:pl-[20%] lg:pr-[20%] bg-transparent text-center'
        }
      >
        <h1
          className={` text-slate-100 text-[3rem] md:text-[5rem] lg:text-[6rem] font-[500] font-['GreatVibes-Regular'] transition-opacity duration-[1000ms] pb-10 lg:pb-20 pt-20 ${
            visible ? 'opacity-100' : 'opacity-0'
          }`}
          style={{ transitionDelay: '100ms' }}
        >
          Děkuji za důvěru
        </h1>
      </section>
      <section className={'p-8 pb-20 lg:pl-[20%] lg:pr-[20%] bg-custom-test'}>
        <h1 className='text-center p-2 '>
          <ArticleTitle text='Kam dál' line />
        </h1>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
          {articles?.articles?.map(
            (article: {
              id: string;
              title: string;
              summary: string;
              image: string;
              hide: boolean;
            }) =>
              !article.hide && (
                <div
                  key={article.id}
                  className='p-4 border rounded shadow hover:shadow-lg transition-shadow duration-200 bg-white'
                >
                  <ArticleLink
                    articleId={article.id}
                    title={article.title}
                    summary={article.summary}
                  >
                    {/* Image at the top */}
                    <img
                      loading='lazy'
                      src={'/content-data/articles/img/' + article.image}
                      alt={article.title}
                      className='w-full h-48 object-cover mb-4' // Margin-bottom to separate from the header
                    />
                    {/* Summary at the bottom */}
                  </ArticleLink>
                </div>
              )
          )}
        </div>
      </section>
      <AuthorSign />
      <Subscribe />
    </div>
  );
};

export default ThankYouPage;
