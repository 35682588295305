import React from 'react';
import ContactForm from '../components/ContactForm';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../components/StyledButton';
import ArticleTitle from '../components/ArticleTitle';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SocialImageLink from '../components/SocialImageLink';
import { handleNavigateToQA } from '../helpers/navigations';

const Contact: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className='w-screen bg-transparent min-h-screen flex flex-col justify-center pb-24 lg:pb-24'>
      <div className='flex flex-col ml-[10%] pt-24 lg:pt-0 h-auto lg:h-2/3 w-[80%] lg:w-[55%] bg-custom-test justify-start'>
        <div className='text-center pt-4 -mb-4'>
          <ArticleTitle text='Máš otázku?' />
        </div>
        <div className='flex flex-col lg:flex-row bg-custom-test'>
          <section className='flex flex-col lg:w-1/2 p-5 justify-start items-center '>
            <p className='pt-4 pb-4 font-[600] leading-[2] text-lg text-pretty text-transparent'>
              Ráda zodpovím jakékoliv dotazy 😊{' '}
            </p>

            <div className='flex pt-4 -ml-2 w-full justify-center'>
              <StyledButton
                text='Možná najdeš svou otázku zde'
                handleClick={() => handleNavigateToQA(navigate)}
              />
            </div>
            <div className='flex flex-row space-x-12 -ml-2 pb-4 pt-6 items-center text-amber-900'>
              <SocialImageLink
                image={faEnvelope}
                link={process.env.REACT_APP_MAIL_LINK ?? ''}
              />
              <SocialImageLink
                image={['fab', 'instagram']}
                link={process.env.REACT_APP_IG_LINK ?? ''}
              />
              <SocialImageLink
                image={['fab', 'facebook']}
                link={process.env.REACT_APP_FB_LINK ?? ''}
              />
            </div>
          </section>
          <section className='lg:w-1/2'>
            <ContactForm show />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Contact;
