import React, { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import NewNavigationBar from './NewNavigationBar';
import MobileNavigationBar from './NavigationBarMobile';
import bgImage from '../util/img/DSC_6049.webp';

const NewHeader: React.FC = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('AppContext must be used within an AppProvider');
  }

  return (
    <>
      <header
        className='fixed top-0 left-0 w-full h-auto lg:h-24 z-20 bg-cover bg-center flex flex-col items-center'
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundAttachment: 'fixed',
        }}
      >
        <NewNavigationBar />
        <MobileNavigationBar />
      </header>
    </>
  );
};

export default NewHeader;
