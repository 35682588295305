import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../context/AppContext';
import { handleNavigateToReservation } from '../helpers/navigations';
import { useNavigate } from 'react-router-dom';

const Ribbon: React.FC = () => {
  const { setActiveDiscount } = useAppContext();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const divRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (!divRef.current) return;

    const rect = divRef.current.getBoundingClientRect();
    const totalPageHeight = document.documentElement.scrollHeight;
    const scrolledFromTop = window.scrollY;

    // Calculate the bottom position of the div relative to the page
    const divBottomPosition = scrolledFromTop + rect.bottom;

    // Calculate distance of the div's bottom edge from the end of the page
    const distanceFromPageBottom = totalPageHeight - divBottomPosition;

    // Hide if the div's bottom edge is within 20px of the page bottom
    setIsVisible(distanceFromPageBottom > 20);
  };

  const handleClick = () => {
    const disc = {
      id: 'BLACKFRIDAY',
      discountPerc: 0.4,
      discountVal: null,
      active: true,
    };
    setActiveDiscount(disc);
    handleNavigateToReservation(navigate);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`fixed top-32 cursor-pointer transition-all duration-300 ease-in-out hover:scale-105 right-0 z-[-10] xl:z-[60] w-auto rotate-45 transform origin-bottom-right`}
        onClick={() => {
          handleClick();
        }}
      >
        <div className='relative xl:pl-[6rem] xl:pr-[6rem] bg-pripary-button-color justify-center text-center text-black text-sm font-bold'>
          <p className='hidden xl:block pt-3 '>Sleva 40% s kódem</p>
          <p className='hidden xl:block'>BLACKFRIDAY</p>
          <p className='hidden xl:block mb-3 text-white bg-red-500 w-full'>
            do 15.12.2024
          </p>
          <p className='block xl:hidden pt-3 '>Sleva 40% s kódem BLACKFRIDAY</p>
          <p className='block xl:hidden pb-3 text-white bg-red-500'>
            do 15.12.2024
          </p>
        </div>
      </div>
      <div
        ref={divRef}
        className={`fixed xl:hidden bottom-0 xl:top-32 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 right-0 z-[60] w-full xl:w-auto xl:rotate-45 xl:transform xl:origin-bottom-right ${
          isVisible ? 'translate-y-0' : 'translate-y-full'
        }`}
        onClick={() => {
          handleClick();
        }}
      >
        <div className='relative xl:pl-[6rem] xl:pr-[6rem] bg-pripary-button-color justify-center text-center text-black text-sm font-bold'>
          <p className='hidden xl:block pt-3 '>Sleva 40% s kódem</p>
          <p className='hidden xl:block'>BLACKFRIDAY</p>
          <p className='hidden xl:block mb-3 text-white bg-red-500 w-full'>
            do 15.12.2024
          </p>
          <p className='block xl:hidden pt-3 '>Sleva 40% s kódem BLACKFRIDAY</p>
          <p className='block xl:hidden pb-3 text-white bg-red-500'>
            do 15.12.2024
          </p>
        </div>
      </div>
    </>
  );
};

export default Ribbon;
