import React, { useEffect, useState } from 'react';
import ArticleTitle from '../components/ArticleTitle';
import StyledButton from '../components/StyledButton';
import { useLocation, useNavigate } from 'react-router-dom';
import ReservationItem from '../components/ReservationItem';
import {
  faClock,
  faEnvelope,
  faBell,
} from '@fortawesome/free-regular-svg-icons';
import { createPaymentLink, fetchApiKey } from '../helpers/phpHelper';
import VoucherItem from '../components/VoucherItem';
import DiscountItem from '../components/DiscountItem';
import { Discount } from '../context/interfaces';
import { handleNavigateToPrubeh } from '../helpers/navigations';
import { useAppContext } from '../context/AppContext';

const ReservationPage: React.FC = () => {
  const [activeDiscount, setActiveDiscount] = useState<Discount | null>(null);
  const navigate = useNavigate();
  const { state, setActiveMeeting } = useAppContext();

  const handleItemClick = (refLink: string | null) => {
    if (refLink?.includes('ERR')) setActiveMeeting(null);
  };

  const handleVoucher60 = () => {
    let priceLink = 'price_1QLo3vJYzHnsoLw2yuZLtWii'; //2000
    if (!state.activeDiscount) {
      createPaymentLink(priceLink, 1, '', activeDiscount?.id);
    } else {
      if (
        state.activeDiscount.id === 'POPRVE' ||
        state.activeDiscount.id === 'JSISKVELA'
      ) {
        priceLink = 'price_1QPTHiJYzHnsoLw2V4vHUJ3A'; //1500
      } else if (state.activeDiscount.id === 'SPECIALNISLEVA') {
        priceLink = 'price_1QPTHiJYzHnsoLw2uNNKglpK'; //1800
      } else if (
        state.activeDiscount.id === 'NOVEZACATKY' ||
        state.activeDiscount.id === 'MIRU35'
      ) {
        priceLink = 'price_1QPTHiJYzHnsoLw2xR92FRHB'; //1300
      } else if (
        state.activeDiscount.id === 'CESTADUSE' ||
        state.activeDiscount.id === 'PRVNISEZENI'
      ) {
        priceLink = 'price_1QPTHiJYzHnsoLw2roBPRhCc'; //1600
      } else if (state.activeDiscount.id === 'BLACKFRIDAY') {
        priceLink = 'price_1QPTHiJYzHnsoLw2N5szW7dM'; //1200
      } else if (state.activeDiscount.id === 'CASPROTEBE') {
        priceLink = 'price_1QPTHiJYzHnsoLw2wLtze1R6'; //1700
      } else {
        priceLink = 'price_1QLo3vJYzHnsoLw2yuZLtWii'; //2000
      }
      createPaymentLink(priceLink, 1, '', activeDiscount?.id);
    }
  };
  const handleVoucher90 = () => {
    let priceLink = 'price_1QPTIUJYzHnsoLw2odQU6pIc'; //2500
    if (!state.activeDiscount) {
      createPaymentLink(priceLink, 1, '', activeDiscount?.id);
    } else {
      if (
        state.activeDiscount.id === 'NOVEZACATKY' ||
        state.activeDiscount.id === 'MIRU35'
      ) {
        priceLink = 'price_1QPTKIJYzHnsoLw24vALUzGl'; //1625
      } else if (
        state.activeDiscount.id === 'PRVNISEZENI' ||
        state.activeDiscount.id === 'POPRVE' ||
        state.activeDiscount.id === 'JSISKVELA'
      ) {
        priceLink = 'price_1QPTKIJYzHnsoLw21d5O6d19'; //2000
      } else if (state.activeDiscount.id === 'BLACKFRIDAY') {
        priceLink = 'price_1QPTKIJYzHnsoLw2cPIqs4Zk'; //1500
      } else if (state.activeDiscount.id === 'SPECIALNISLEVA') {
        priceLink = 'price_1QPTKIJYzHnsoLw2XAGAUy5J'; //2300
      } else if (state.activeDiscount.id === 'CASPROTEBE') {
        priceLink = 'price_1QPTKIJYzHnsoLw2qkRksYLr'; //2200
      } else if (state.activeDiscount.id === 'CESTADUSE') {
        priceLink = 'price_1QPTKIJYzHnsoLw2i5qowwGc'; //2100
      } else {
        priceLink = 'price_1QPTIUJYzHnsoLw2odQU6pIc'; //2500
      }
      createPaymentLink(priceLink, 1, '', activeDiscount?.id);
    }
  };

  const handleVoucherClick = (voucher: string | null) => {
    if (voucher)
      createPaymentLink('prod_REGbY4o87E7bOR', 1, voucher, activeDiscount?.id);
  };

  const discountEvent = (discount: Discount | null) => {
    setActiveDiscount(discount);
  };

  return (
    <div className='top-0 pt-24 mb-30 w-full lg:pl-[10%] lg:pr-[10%] bg-transparent flex flex-col items-center justify-center'>
      <div className='flex flex-col w-full bg-white/90 text-center mb-[10rem] items-center'>
        <div className='flex flex-col w-full pt-8 bg-custom-test justify-center items-center space-y-4 pb-4 lg:pl-[20%] lg:pr-[20%]'>
          <ArticleTitle text='Chci se domluvit na termínu' />
        </div>
        <section className='flex flex-col w-full p-3 space-y-1'>
          {(!state.activeMeeting || !state.activeMeeting.includes('DAREK')) && (
            <DiscountItem
              text='Slevový kód'
              leftIcon={faBell}
              onValidDiscount={discountEvent}
            />
          )}
          {(!state.activeMeeting || state.activeMeeting.includes('DAREK')) && (
            <VoucherItem
              text='Mám dárkový poukaz'
              leftIcon={faEnvelope}
              onValidVoucher={handleItemClick}
              refLink={'voucher'}
            />
          )}

          <ReservationItem
            text='Theta healing sezení (60 minut)'
            price={2000}
            leftIcon={faClock}
            displayvoucher
            onVoucher={handleVoucher60}
            onClick={handleItemClick}
            refLink='sezeni60min'
          />
          <ReservationItem
            text='Theta healing sezení (90 minut)'
            price={2500}
            leftIcon={faClock}
            displayvoucher
            onVoucher={handleVoucher90}
            onClick={handleItemClick}
            refLink='sezeni90min'
          />
          <ReservationItem
            text='Theta healing sezení (5x 60 minut)'
            price={9000}
            discountPrice='10000 Kč'
            leftIcon={faClock}
            onClick={handleItemClick}
            refLink='sezeni5x60min'
          />
          <ReservationItem
            isVoucher
            text='Dárkový poukaz (60 minut)'
            price={2000}
            leftIcon={faEnvelope}
            onClick={handleVoucherClick}
            refLink={'vou60'}
          />
          <ReservationItem
            isVoucher
            text='Dárkový poukaz (90 minut)'
            price={2500}
            leftIcon={faEnvelope}
            onClick={handleVoucherClick}
            refLink={'vou90'}
          />
        </section>
        {state.activeMeeting && (
          <div
            style={{ width: '100%', height: '100vh', overflowY: 'hidden' }}
            className='flex pl-3 pr-3 items-center'
          >
            <iframe
              onChange={(event) => {
                console.log(event);
              }}
              title='reservationFrame'
              src={`https://app.simplymeet.me/cestasvetla/${state.activeMeeting}?is_widget=1&view=compact`}
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                border: 'none',
                overflow: 'hidden',
              }}
              frameBorder='0'
              scrolling='no'
            />
          </div>
        )}

        <h2 className='text-2xl font-bold pb-8 pt-8 text-amber-900'>
          Těším se na tebe!
        </h2>
      </div>
    </div>
  );
};

export default ReservationPage;
