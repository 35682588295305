import React from 'react';

interface StyledButtonProps {
  handleClick: () => void;
  text: string;
  loading?: boolean;
  secondary?: boolean;
}

const StyledButton: React.FC<StyledButtonProps> = ({
  text,
  handleClick,
  loading = false,
  secondary = false,
}) => {
  const handleButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleClick();
  };

  return (
    <button
      type='submit'
      onClick={handleButtonClick}
      disabled={loading} // Disable button when loading
      className={`relative flex w-full items-center justify-center text-base py-3 px-6 rounded-xl transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 font-[500] ${
        loading
          ? 'bg-gray-400 cursor-not-allowed'
          : secondary
          ? 'bg-amber-900 hover:bg-amber-900 text-white hover:text-white'
          : 'bg-[#f4c9d5] hover:bg-amber-900 text-black hover:text-white'
      }`}
    >
      {loading ? (
        <div className='flex space-x-2'>
          <span className='h-2 w-2 bg-white rounded-full animate-pulse'></span>
          <span className='h-2 w-2 bg-white rounded-full animate-pulse delay-200'></span>
          <span className='h-2 w-2 bg-white rounded-full animate-pulse delay-400'></span>
        </div>
      ) : (
        text
      )}
    </button>
  );
};

export default StyledButton;
