import { Discount } from '../context/interfaces';

export const fetchApiKey = async (service: string) => {
  try {
    const response = await fetch(`./api/getApiKey.php?service=${service}`);
    const data = await response.json();
    if (data.apiKey) {
      window.alert('loaded:' + data.apiKey);
      return data.apiKey;
    } else {
      throw new Error('API key not found');
    }
  } catch (error) {
    console.error('Error fetching API key:', error);
  }
};

export const createPaymentLink = async (
  productId: any,
  quantity: any,
  orderId: string,
  promoCode?: any
) => {
  fetch('./api/create-payment-link.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      productId, // ID of the product
      quantity, // Quantity of the product
      promoCode,
      orderId,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (data.paymentLink) {
        window.location.href = data.paymentLink; // Redirect to Stripe Payment Link
      } else {
        console.error('Error:', data.error);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  // try {
  //   const response = await fetch(
  //     'https://cestasvetla.eu/api/create-payment-link.php',
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         productId, // ID of the product
  //         quantity, // Quantity of the product
  //         promoCode,
  //         orderId,
  //       }),
  //     }
  //   );

  //   const data = await response.json();

  //   if (data.paymentLink) {
  //     // Redirect to the payment link
  //     window.location.href = data.paymentLink;
  //   } else {
  //     console.error('Error:', data.error);
  //   }
  // } catch (error) {
  //   console.error('Error creating payment link:', error);
  // }
};

export const validateDiscountBE = async (
  code: string
): Promise<Discount | null> => {
  try {
    const response = await fetch('./api/verifyDiscount.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });
    console.log(response);
    let result = await response.json();

    // Check if there is an error in the response
    if (result.error) {
      return null; // Return null if there is an error (invalid or inactive code)
    }

    // Return the discount data if valid
    return {
      id: code,
      active: true,
      discountPerc: result.type === 'percent' ? result.discount : null,
      discountVal: result.type === 'value' ? result.discount : null,
    } as Discount;
  } catch (error) {
    // Handle network errors or other issues
    console.error('Error validating discount:', error);
    return null; // Return null in case of any error
  }
};

export const validateVoucherBE = async (
  code: string
): Promise<boolean | null> => {
  try {
    const response = await fetch('./api/verifyVoucher.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });
    console.log(response);
    let result = await response.json();

    // Check if there is an error in the response
    if (result.error) {
      return null; // Return null if there is an error (invalid or inactive code)
    }

    // Return the discount data if valid
    return result;
  } catch (error) {
    // Handle network errors or other issues
    console.error('Error validating discount:', error);
    return null; // Return null in case of any error
  }
};
