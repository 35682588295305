import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import StyledButton from './StyledButton';
import { validateVoucherBE } from '../helpers/phpHelper';
import { useAppContext } from '../context/AppContext';

interface ReservationItemProps {
  text: string;
  subtext?: string;
  leftIcon: IconProp;
  onValidVoucher: (refLink: string | null) => void;
  refLink: string | null;
}
//TODO: validate php, load php, invalidate code after usage
const VoucherItem: React.FunctionComponent<ReservationItemProps> = ({
  text,
  subtext,
  leftIcon,
  onValidVoucher,
  refLink,
}) => {
  const { state, setActiveMeeting } = useAppContext();
  const form = useRef<HTMLFormElement | null>(null);
  const [finalRefLink, setFinalRefLink] = useState<string>('');
  const [voucherValid, setVoucherValid] = useState<boolean | null>(null);

  const getPlaceholderText = () => {
    if (voucherValid === null) return 'Zadej kód poukazu';
    if (voucherValid) return '';
    if (!voucherValid) return 'Neznámý kód';
  };

  const validateVoucher = (code: string) => {
    validateVoucherBE(code).then((response) => {
      const matchFound = response;
      setVoucherValid(matchFound);
      if (matchFound) buildLink(code);
      else form.current?.reset();
    });
  };

  const buildLink = (code: string) => {
    if (code.includes('DAREK60')) {
      setFinalRefLink(refLink + 's60');
    }
    if (code.includes('DAREK90')) {
      setFinalRefLink(refLink + 's90');
    } else {
      setFinalRefLink(code);
    }
  };

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (form.current) {
      validateVoucher(form.current?.discount_code.value);
    }
  };

  useEffect(() => {
    if (voucherValid) {
      setActiveMeeting(finalRefLink);
      onValidVoucher(finalRefLink);
    } else onValidVoucher('ERR-invalid');
  }, [finalRefLink]);

  return (
    <section
      className={`flex flex-col lg:flex-row w-full justify-between p-2 pl-4 pr-4  bg-custom-test`}
    >
      <div className='flex flex-col lg:flex-row text-center items-center'>
        {state.activeMeeting && (
          <p className='flex pr-3'>
            <StyledButton
              handleClick={() => {
                setActiveMeeting(null);
              }}
              text='Zpět na výběr'
            />
          </p>
        )}
        {/* <p className='text-black'>
          <FontAwesomeIcon
            className={`relative bottom-0 h-[1.5rem] w-[1.5rem] lg:pr-4`}
            icon={leftIcon}
            size='xl'
          />
        </p> */}
        <h1 className='font-[500] '>{text}</h1>
      </div>
      <form
        ref={form}
        onSubmit={formSubmit}
        className='flex flex-col lg:flex-row pt-4 lg:pt-0 p-0 m-0 justify-center items-center space-y-3 lg:space-y-0'
      >
        <div className='flex flex-row min-h-10'>
          <input
            type='text'
            name='discount_code'
            placeholder={getPlaceholderText()}
            className='block pl-10 pr-10 w-full min-h-5 rounded-sm lg:rounded-xl text-base focus:border-transparent'
            required
          />
        </div>
        <div className='flex lg:ml-6 max-h-10'>
          <StyledButton text={'Uplatnit'} handleClick={() => {}} />
        </div>
      </form>
    </section>
  );
};

export default VoucherItem;
